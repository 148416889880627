import logo from "./logo.svg";
import { lazy, Suspense } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";
import Loading from "./screens/Loading";
import Home from "./screens/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Illinois from "./screens/States/illinois";
import Kansas from "./screens/States/Kansas";
import California from "./screens/States/california";
import Oregon from "./screens/States/oregon";
import Newmexico from "./screens/States/new-mexico";
import Alaska from "./screens/States/alaska";
import Georgia from "./screens/States/georgia";
import Nebraska from "./screens/States/nebraska";
import Northdakota from "./screens/States/north-dakota";
import Rhodeisland from "./screens/States/rhode-island";
import Southdakota from "./screens/States/south-dakota";
import Kentucky from "./screens/States/kentucky";
import Districtofcolumbia from "./screens/States/district-of-columbia";
import Oklahoma from "./screens/States/oklahoma";
import Utah from "./screens/States/utah";
import Wisconsin from "./screens/States/wisconsin";
import Minnesota from "./screens/States/minnesota";
import Southcarolina from "./screens/States/south-carolina";
import Texas from "../src/screens/States/texas-state";
import Vermont from "./screens/States/vermont";

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Header/>
        <Home/>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
          <Route path="/daily-fantasy-sports-in-texas" element={<Texas />} />
          <Route path="/daily-fantasy-sports-in-illinois" element={<Illinois />} />
          <Route path="/daily-fantasy-sports-in-kansas" element={<Kansas />} />
          <Route path="/daily-fantasy-sports-in-california" element={<California />} />
          <Route path="/daily-fantasy-sports-in-oregon" element={<Oregon />} />
          <Route path="/daily-fantasy-sports-in-new-mexico" element={<Newmexico />} />
          <Route path="/daily-fantasy-sports-in-alaska" element={<Alaska />} />
          <Route path="/daily-fantasy-sports-in-georgia" element={<Georgia />} />
          <Route path="/daily-fantasy-sports-in-nebraska" element={<Nebraska />} />
          <Route path="/daily-fantasy-sports-in-north-dakota" element={<Northdakota />} />
          <Route path="/daily-fantasy-sports-in-rhode-island" element={<Rhodeisland />} />
          <Route path="/daily-fantasy-sports-in-south-dakota" element={<Southdakota />} />
          <Route path="/daily-fantasy-sports-in-kentucky" element={<Kentucky />} />
          <Route path="/daily-fantasy-sports-in-district-of-columbia" element={<Districtofcolumbia />} />
          <Route path="/daily-fantasy-sports-in-south-carolina" element={<Southcarolina />} />
          <Route path="/daily-fantasy-sports-in-minnesota" element={<Minnesota />} />
          <Route path="/daily-fantasy-sports-in-wisconsin" element={<Wisconsin />} />
          <Route path="/daily-fantasy-sports-in-utah" element={<Utah />} />
          <Route path="/daily-fantasy-sports-in-oklahoma" element={<Oklahoma />} />
          <Route path="/daily-fantasy-sports-in-vermont" element={<Vermont />} />
        
 
        </Routes>
        <Footer/>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
