import { useState, useEffect } from "react";

import "./home.css";

import Header from "../../components/Header";
import axios from "axios";
import appstorelogo from "../../assests/images/website/appstore.svg";
import Toaster from "../Toast";

import { ErrorToast, SuccesToast } from "../Toast/message";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
const Home = () => {
  const [value, setValue] = useState("");
  const [mail, setmail] = useState("");
  const [confirm, setconfirm] = useState(false);

  const location = useLocation();

  let params = new URLSearchParams(window.location.search);
  let ref = params.get("ref") || "";
  let campaign = params.get("campaign") || "";
  function setCookie(name, value, days) {
    const expirationDate = new Date();
    expirationDate.setTime(
      expirationDate.getTime() + days * 24 * 60 * 60 * 1000
    );
    const expires = "expires=" + expirationDate.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }
  if (ref) {
    setCookie("ref", ref, 30); // Set the cookie to expire in 30 days
  }
  if (campaign) {
    setCookie("campaign", campaign, 30); // Set the cookie to expire in 30 days
  }

  const stateNameArray = [
    { path: "/daily-fantasy-sports-in-texas", name: "IN TEXAS" },
    { path: "/daily-fantasy-sports-in-illinois", name: "IN ILLINOIS" },
    { path: "/daily-fantasy-sports-in-kansas", name: "IN KANSAS" },
    { path: "/daily-fantasy-sports-in-california", name: "IN CALIFORNIA" },
    { path: "/daily-fantasy-sports-in-oregon", name: "IN OREGON" },
    { path: "/daily-fantasy-sports-in-new-mexico", name: "IN NEW MEXICO" },
    { path: "/daily-fantasy-sports-in-alaska", name: "IN ALASKA" },
    { path: "/daily-fantasy-sports-in-georgia", name: "IN GEORGIA" },
    { path: "/daily-fantasy-sports-in-nebraska", name: "IN NEBRASKA" },
    { path: "/daily-fantasy-sports-in-north-dakota", name: "IN NORTH DAKOTA" },
    { path: "/daily-fantasy-sports-in-rhode-island", name: "IN RHODE ISLAND" },
    { path: "/daily-fantasy-sports-in-south-dakota", name: "IN SOUTH DAKOTA" },
    { path: "/daily-fantasy-sports-in-kentucky", name: "IN KENTUCKY" },
    {
      path: "/daily-fantasy-sports-in-district-of-columbia",
      name: "IN DISTRICT OF COLUMBIA",
    },
    {
      path: "/daily-fantasy-sports-in-south-carolina",
      name: "IN SOUTH CAROLINA",
    },
    { path: "/daily-fantasy-sports-in-minnesota", name: "IN MINNESOTA" },
    { path: "/daily-fantasy-sports-in-wisconsin", name: "IN WISCONSIN" },
    { path: "/daily-fantasy-sports-in-utah", name: "IN UTAH" },
    { path: "/daily-fantasy-sports-in-oklahoma", name: "IN OKLAHOMA" },
    { path: "/daily-fantasy-sports-in-vermont", name: "IN VERMONT" },
  ];

  const getStateName = () => {
    const match = stateNameArray.find(
      (item) => item?.path === location.pathname
    );
    return match ? match?.name : ""; // Return the state name if found, otherwise return an empty string
  };

  const stateName = getStateName();

  const [currentIndex, setCurrentIndex] = useState(0);
  const messages = [
    {
      h3: "UNLEASH YOUR WINNING POTENTIAL!",
      p1: "Multiply your winnings up to 125x your entry!",
      p2: "Supercharge your daily play with a $5 daily bonus! Use it to fuel your contests with a 50/50 split: 50% bonus money and 50% real money.",
    },
    {
      h3: "GET READY FOR A MASSIVE FIRST DEPOSIT OFFER!",
      p1: "Receive UP TO $100 MATCH AMOUNT on your first deposit!",
      p2: `Don't miss out! Start playing now and maximize your chances of winning big!`,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 10000); // 10 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [messages?.length]);

  return (
    <>
      <Toaster />
      <Helmet>
        <title>
          BettDraft | Play Daily Fantasy Sports & win up to 125X DFS payouts!
        </title>
        <meta
          name="description"
          content="Play Daily Fantasy Sports at BettDraft. Draft teams with top players from major leagues: NFL, MLB, NBA, NHL, MLS, and more."
        />
      </Helmet>
      <div>
        {/* <Header /> */}

        {/* <section className="sec-padd banner-section">
          <div className="container">
            <h1 className="title-p mb-0 font-bold">
              DAILY FANTASY WITH THE BEST PAYOUTS {stateName}
            </h1>
            <span className="big-font text-center">
              Get UP TO $100
            </span>
     
            <h4>First Deposit Match</h4>
            <div className="flex-center mt-5">
              <a
                className="btn-c"
                href={
                  ref && campaign
                    ? `${`https://dfs.bettdraft.com/join-now?ref=${ref}&campaign=${campaign}`}`
                    : "https://dfs.bettdraft.com/join-now"
                }
              >
                Play Now
              </a>
           
            </div>
          </div>
        </section> */}
     
        <section className="sec-padd banner-section">
          <div className="container">
    
            <h3 className="plustext">{messages[currentIndex].h3}</h3>

            <p className="title-p mb-0 font-bold">
              {messages[currentIndex].p1}
            </p>
            <p className="title-p mb-0 font-bold">
              {messages[currentIndex].p2}{" "}
            </p>

            <div className="flex-center mt-5">
              <a
                className="btn-c"
                href={
                  ref && campaign
                    ? `${`https://dfs.bettdraft.com/join-now?ref=${ref}&campaign=${campaign}`}`
                    : "https://dfs.bettdraft.com/join-now"
                }
              >
                Play Now
              </a>
            </div>
          </div>
        </section>

        <section className="sec-padd bg-pattern ">
          <div className="container">
            <div className="row justify-content-center ">
              <div className="col-md-6">
                <h2 className="medium-heading mt-2 mb-4">
                  Exclusively <span>on BettDraft</span>
                </h2>
                <p className="medium-p mb-0">Play for up to </p>
                <img
                  className="ms-4"
                  src={require("../../assests/images/website/125x.webp")}
                  alt="BettDraft 125X Payout"
                />
                <p className="medium-p mb-0 end-text">your entry fee</p>

                <p className="second-p mb-0">
                  Pick your favorite players to get started
                </p>
                <img
                  src={require("../../assests/images/website/group-img.webp")}
                  alt="BettDraft Player's Headshot"
                />
                <div className="flex-center mt-2">
                  <a
                    className="btn-c"
                    href={
                      ref && campaign
                        ? `${`https://dfs.bettdraft.com/join-now?ref=${ref}&campaign=${campaign}`}`
                        : "https://dfs.bettdraft.com/join-now"
                    }
                  >
                    Play Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sec-barcode bg-pattern sec-padd">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6 ">
                <div className="newSection">
                  <img
                    src={require("../../assests/images/website/Phone.png")}
                    alt="BettDraft NBA"
                  />
                </div>
              </div>

              <div className="col-md-6 ">
                <div className="newSection">
                  <div className="row align-bottom">
                    <div className="row justify-content-center">
                      {/* <img src={require("../../assests/images/website/bettdraftqr.png")} alt="" className="barcodeImg" /> */}

                      {/* <a
                        href="https://play.google.com/store/apps/details?id=com.bettdraft"
                        rel="nofollow"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "25px",
                          marginTop: "30px",
                        }}
                      >
                        <img
                          src={require("../../assests/images/website/playstoremain.png")}
                          alt=""
                          style={{ height: "108px" }}
                        />
                      </a> */}

                      <a
                        href="https://apps.apple.com/us/app/bettdraft-fantasy-sports-game/id6467048047"
                        rel="nofollow"
                        className="appstoreImg"
                        // style={{ display: "flex", justifyContent: "center",marginTop:'10rem' }}
                      >
                        <img
                          src={appstorelogo}
                          alt=""
                          style={{ height: "80px" }}
                        />
                      </a>
                      {/* <p className="flex-p mt-2"><span>Got Android?</span> <a href={androidapp}>CLICK HERE</a> to download the APP.</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sec-map1 sec-padd">
          <div className="container">
            <h2 className="c-heading text-center">BettDraft is available in</h2>
            <p className="text-center text-p state">
              You can now play in AK, CA, DC, GA, IL, KS, KY, MN, ND, NE, NM,
              OK, OR, RI, SC, SD, TX, UT, WI & VT.
            </p>
            <img
              src={require("../../assests/images/website/bettdraftmap-copy.webp")}
              alt="BettDraft Map"
              style={{ width: "100%" }}
            />
            <div className="flex-center mt-2 mb-2">
              <a
                className="btn-c"
                href={
                  ref && campaign
                    ? `${`https://dfs.bettdraft.com/join-now?ref=${ref}&campaign=${campaign}`}`
                    : "https://dfs.bettdraft.com/join-now"
                }
              >
                Play Now
              </a>
            </div>
            {/* <p className="small-p w-100">PLAY DAILY FANTASY AT BETTDRAFT TODAY!</p> */}
          </div>
        </section>

        {/* <footer className="sec-padd bg-gray">
          <div className="container">
            <div className="row  gy-3">
              <div className="col-md-3">
                <a
                  href={
                    ref && campaign
                      ? `${`https://dfs.bettdraft.com?ref=${ref}&campaign=${campaign}`}`
                      : "https://dfs.bettdraft.com"
                  }
                >
                  <img
                    className="mb-3"
                    src={require("../../assests/images/website/logo.png")}
                    alt="BettDraft Logo"
                  />
                </a>

                <div className="flex-footer">
                  <a
                    href="https://apps.apple.com/us/app/bettdraft-fantasy-sports-game/id6467048047"
                    rel="nofollow"
                    style={{
                      textDecoration: "none",
                      height: "45px",
                      width: "100%",
                      marginTop: "-12px",
                      cursor: "pointer",
                    }}
                  >
                    <img src={appstorelogo} />
                  </a>
                  
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">
                    <h5 className="footer-heading">SUPPORT</h5>
                    <ul>
                      <li>
                        <a
                          href={
                            ref && campaign
                              ? `${`https://dfs.bettdraft.com/contact-us?ref=${ref}&campaign=${campaign}`}`
                              : "https://dfs.bettdraft.com/contact-us"
                          }
                        >
                          Contact us
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            ref && campaign
                              ? `${`https://dfs.bettdraft.com/how-to-play?ref=${ref}&campaign=${campaign}`}`
                              : "https://dfs.bettdraft.com/how-to-play"
                          }
                        >
                          How to play
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            ref && campaign
                              ? `${`https://dfs.bettdraft.com/faq?ref=${ref}&campaign=${campaign}`}`
                              : "https://dfs.bettdraft.com/faq"
                          }
                        >
                          FAQs
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-8">
                    <h6 className="footer-heading">PLAYING AT BETTDRAFT</h6>
                    <ul className="ul-col">
                      <li>
                        <a
                          href={
                            ref && campaign
                              ? `${`https://dfs.bettdraft.com/about-us?ref=${ref}&campaign=${campaign}`}`
                              : "https://dfs.bettdraft.com/about-us"
                          }
                        >
                          About Us
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            ref && campaign
                              ? `${`https://dfs.bettdraft.com/termsconditions?ref=${ref}&campaign=${campaign}`}`
                              : "https://dfs.bettdraft.com/termsconditions"
                          }
                        >
                          Terms and Conditions
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            ref && campaign
                              ? `${`https://dfs.bettdraft.com/privacy-policy?ref=${ref}&campaign=${campaign}`}`
                              : "https://dfs.bettdraft.com/privacy-policy"
                          }
                        >
                          Privacy Policy
                        </a>
                      </li>

                      <li>
                        <a
                          href={
                            ref && campaign
                              ? `${`https://dfs.bettdraft.com/house-rules?ref=${ref}&campaign=${campaign}`}`
                              : "https://dfs.bettdraft.com/house-rules"
                          }
                        >
                          House Rules
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            ref && campaign
                              ? `${`https://dfs.bettdraft.com/responsible-gaming?ref=${ref}&campaign=${campaign}`}`
                              : "https://dfs.bettdraft.com/responsible-gaming"
                          }
                        >
                          Responsible Gaming
                        </a>
                      </li>
                      <li>
                        <a
                          href={"https://play.spinsagacasino.com"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Social Casino
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="flex-footer footer-social">
                  <a href="https://www.instagram.com/bettdraft/" rel="nofollow">
                    {" "}
                    <img
                      src={require("../../assests/images/website/Instagram.png")}
                    />
                  </a>
                  <a href="https://www.facebook.com/bettdraft" rel="nofollow">
                    {" "}
                    <img
                      src={require("../../assests/images/website/Facebook.png")}
                    />{" "}
                  </a>
                  <a href="https://twitter.com/BettDraft" rel="nofollow">
                    {" "}
                    <img
                      src={require("../../assests/images/website/Twitter.png")}
                    />
                  </a>
                </div>
                
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p className="copy-p text-center mb-0">
                  © BettDraft All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </footer> */}
      </div>
    </>
  );
};

export default Home;
