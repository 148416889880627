import React from "react";
import "./footer.css";
import appstorelogo from "../../assests/images/website/appstore.svg";
const Footer = () => {
  let params = new URLSearchParams(window.location.search);
  let ref = params.get("ref") || "";
  let campaign = params.get("campaign") || "";
  function setCookie(name, value, days) {
    const expirationDate = new Date();
    expirationDate.setTime(
      expirationDate.getTime() + days * 24 * 60 * 60 * 1000
    );
    const expires = "expires=" + expirationDate.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }
  if (ref) {
    setCookie("ref", ref, 30); // Set the cookie to expire in 30 days
  }
  if (campaign) {
    setCookie("campaign", campaign, 30); // Set the cookie to expire in 30 days
  }
  return (
    <div>
      <footer className="sec-padd bg-gray">
        <div className="container">
          <div className="row  gy-3">
            <div className="col-md-3">
              <a
                href={
                  ref && campaign
                    ? `${`https://dfs.bettdraft.com?ref=${ref}&campaign=${campaign}`}`
                    : "https://dfs.bettdraft.com"
                }
              >
                <img
                  className="mb-3"
                  src={require("../../assests/images/website/logo.png")}
                  alt="BettDraft Logo"
                />
              </a>

              <div className="flex-footer">
                <a
                  href="https://apps.apple.com/us/app/bettdraft-fantasy-sports-game/id6467048047"
                  rel="nofollow"
                  style={{
                    textDecoration: "none",
                    height: "45px",
                    width: "100%",
                    marginTop: "-12px",
                    cursor: "pointer",
                  }}
                >
                  <img src={appstorelogo} />
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-4">
                  <h5 className="footer-heading">SUPPORT</h5>
                  <ul>
                    <li>
                      <a
                        href={
                          ref && campaign
                            ? `${`https://dfs.bettdraft.com/contact-us?ref=${ref}&campaign=${campaign}`}`
                            : "https://dfs.bettdraft.com/contact-us"
                        }
                      >
                        Contact us
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          ref && campaign
                            ? `${`https://dfs.bettdraft.com/how-to-play?ref=${ref}&campaign=${campaign}`}`
                            : "https://dfs.bettdraft.com/how-to-play"
                        }
                      >
                        How to play
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          ref && campaign
                            ? `${`https://dfs.bettdraft.com/faq?ref=${ref}&campaign=${campaign}`}`
                            : "https://dfs.bettdraft.com/faq"
                        }
                      >
                        FAQs
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-8">
                  <h6 className="footer-heading">PLAYING AT BETTDRAFT</h6>
                  <ul className="ul-col">
                    <li>
                      <a
                        href={
                          ref && campaign
                            ? `${`https://dfs.bettdraft.com/about-us?ref=${ref}&campaign=${campaign}`}`
                            : "https://dfs.bettdraft.com/about-us"
                        }
                      >
                        About Us
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          ref && campaign
                            ? `${`https://dfs.bettdraft.com/termsconditions?ref=${ref}&campaign=${campaign}`}`
                            : "https://dfs.bettdraft.com/termsconditions"
                        }
                      >
                        Terms and Conditions
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          ref && campaign
                            ? `${`https://dfs.bettdraft.com/privacy-policy?ref=${ref}&campaign=${campaign}`}`
                            : "https://dfs.bettdraft.com/privacy-policy"
                        }
                      >
                        Privacy Policy
                      </a>
                    </li>

                    <li>
                      <a
                        href={
                          ref && campaign
                            ? `${`https://dfs.bettdraft.com/house-rules?ref=${ref}&campaign=${campaign}`}`
                            : "https://dfs.bettdraft.com/house-rules"
                        }
                      >
                        House Rules
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          ref && campaign
                            ? `${`https://dfs.bettdraft.com/responsible-gaming?ref=${ref}&campaign=${campaign}`}`
                            : "https://dfs.bettdraft.com/responsible-gaming"
                        }
                      >
                        Responsible Gaming
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://play.spinsagacasino.com"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Social Casino
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="flex-footer footer-social">
                <a href="https://www.instagram.com/bettdraft/" rel="nofollow">
                  {" "}
                  <img
                    src={require("../../assests/images/website/Instagram.png")}
                  />
                </a>
                <a href="https://www.facebook.com/bettdraft" rel="nofollow">
                  {" "}
                  <img
                    src={require("../../assests/images/website/Facebook.png")}
                  />{" "}
                </a>
                <a href="https://twitter.com/BettDraft" rel="nofollow">
                  {" "}
                  <img
                    src={require("../../assests/images/website/Twitter.png")}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p className="copy-p text-center mb-0">
                © BettDraft All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
